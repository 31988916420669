const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

(function($) {
	$(document).on('click', '[data-action="modal"]', function(e) {
		let target = $(this).data('target');
		const targetElement = document.querySelector(target);

		$(document)
			.find(target)
			.addClass('is-active');

		disableBodyScroll(targetElement);
	});

	$(document).on('click', '[data-action="modal-download"]', function(e) {
		let targetEl = $(this).data('target');
		const targetElement = document.querySelector(targetEl);

		$(document)
			.find(targetEl)
			.addClass('is-active');

		disableBodyScroll(targetElement);
	});

	const modalOuter = document.querySelectorAll('.modal');

	$(modalOuter).on('click', function(e) {
		const isOutside = !e.target.closest('.modal__inner');
		if(isOutside){
			$(this)
			.closest('.modal')
			.removeClass('is-active');

			let target = $(this)
				.closest('.modal')
				.attr('id');

			const targetElement = document.querySelector('#' + target);

			enableBodyScroll(targetElement);
		}
	});

	$(document).on('click', '[data-action="close-modal"]', function(e) {
		$(this)
			.closest('.modal')
			.removeClass('is-active');

		let target = $(this)
			.closest('.modal')
			.attr('id');

		const targetElement = document.querySelector('#' + target);

		enableBodyScroll(targetElement);
	});

})(jQuery);
