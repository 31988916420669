var Parsley = require('parsleyjs');

import './components/images.js';
import './components/menu.js';
import './components/modal.js';
import './components/form.js';
import FilterState from './components/FilterState.js';
import Mix from './components/mix.js';

import customSelect from 'custom-select';
import ClipboardJS from 'clipboard';

var objectFitImages = require('object-fit-images');

(function($) {
	Parsley.addMessages('nl', {
		defaultMessage: 'Deze waarde lijkt onjuist.',
		type: {
			email: 'Dit lijkt geen geldig e-mailadres te zijn.',
			url: 'Dit lijkt geen geldige URL te zijn.',
			number: 'Deze waarde moet een nummer zijn.',
			integer: 'Deze waarde moet een nummer zijn.',
			digits: 'Deze waarde moet numeriek zijn.',
			alphanum: 'Deze waarde moet alfanumeriek zijn.',
		},
		notblank: 'Deze waarde mag niet leeg zijn.',
		required: 'Dit veld is verplicht.',
		pattern: 'Deze waarde lijkt onjuist te zijn.',
		min: 'Deze waarde mag niet lager zijn dan %s.',
		max: 'Deze waarde mag niet groter zijn dan %s.',
		range: 'Deze waarde moet tussen %s en %s liggen.',
		minlength:
			'Deze tekst is te kort. Deze moet uit minimaal %s karakters bestaan.',
		maxlength:
			'Deze waarde is te lang. Deze mag maximaal %s karakters lang zijn.',
		length: 'Deze waarde moet tussen %s en %s karakters lang zijn.',
		equalto: 'Deze waardes moeten identiek zijn.',
	});

	Parsley.setLocale('nl');

	Parsley.addMessages('nl', {
		dateiso:
			'Deze waarde moet een datum in het volgende formaat zijn: (YYYY-MM-DD).',
		minwords: 'Deze waarde moet minstens %s woorden bevatten.',
		maxwords: 'Deze waarde mag maximaal %s woorden bevatten.',
		words: 'Deze waarde moet tussen de %s en %s woorden bevatten.',
		gt: 'Deze waarde moet groter dan %s zijn.',
		lt: 'Deze waarde moet kleiner dan %s zijn.',
	});
	// Einde Parsley

	customSelect('select');

	objectFitImages();

	$(document).ready(function() {
		$('body').trigger('bless');
	});

	new ClipboardJS('.clippy');

	const $multimixcontainer = $('#multimixcontainer');
	if ($multimixcontainer.length > 0) {
		const mix = new Mix($multimixcontainer);
		mix.init();
	}

	// Hele lelijke manier om de placeholder erin te krijgen
	$('.modal-form .custom-select-opener span')
		.addClass('greyed')
		.text('Waar gaat je vraag over?');

	$('body').on(
		'DOMSubtreeModified',
		'.modal-form .custom-select-opener span',
		function() {
			$('.modal-form .custom-select-opener span').removeClass('greyed');
		},
	);

	$('.subscribe__form .custom-select-opener span')
		.addClass('greyed')
		.text('Kies een datum');

	$('body').on(
		'DOMSubtreeModified',
		'.subscribe__form .custom-select-opener span',
		function() {
			$('.subscribe__form .custom-select-opener span').removeClass(
				'greyed',
			);
		},
	);

	$('.filtercolumn').each(function(index, elm) {
		new FilterState($(elm));
	});

	if ($('.backlink--local').length > 0) {
		let url = $('.backlink--local').attr('href');
		let newUrl = url + '#' + window.localStorage.getItem('filterstate');
		console.log(url, newUrl);
		$('.backlink--local').attr('href', newUrl);
	}

	$('.open-collapse').on('click', function(e) {
		$(this).toggleClass('opened');
		$(this)
			.next('ul')
			.toggleClass('open');
	});

	$(document).on('gform_confirmation_loaded', function(event, form_id) {
		console.log(event, form_id);
		if (form_id == 1) {
			$('.section--newsletter').addClass('newsletter-confirmation--show');
		}
	});

	function resetVideo() {
		$('.video__image').removeClass('fadeout');
		player.pauseVideo();
		$('.video__source').removeClass('visible');
	}

	function createVideo(video) {
		var youtubeScriptId = 'youtube-api';
		var youtubeScript = document.getElementById(youtubeScriptId);
		var videoId = video.getAttribute('data-youtube');

		if (youtubeScript === null) {
			var tag = document.createElement('script');
			var firstScript = document.getElementsByTagName('script')[0];
			tag.src = 'https://www.youtube.com/iframe_api';
			tag.id = youtubeScriptId;
			firstScript.parentNode.insertBefore(tag, firstScript);
		}

		window.onYouTubeIframeAPIReady = function() {
			window.player = new window.YT.Player(video, {
				videoId: videoId,
				playerVars: {
					autoplay: 1,
					showinfo: 0,
					modestbranding: 1,
					rel: 0,
				},
				events: {
					onReady: function() {
						$('.js-video .video__image').addClass('fadeout');
						$('.video__source').addClass('visible');
					},
					onStateChange: function(event) {
						if (event.data == 1) {
							$('.js-video .video__image').addClass('fadeout');
							$('.video__source').addClass('visible');
						} else {
							$('.js-video .video__image').removeClass('fadeout');
							$('.video__source').removeClass('visible');
						}
					},
				},
			});
		};
	}

	$('.js-video').on('click', '.video__image', function() {
		console.log('Play video');
		if (typeof window.player != 'undefined') {
			if (player.getPlayerState() == 1) {
				resetVideo();
			} else {
				player.playVideo();
			}
		} else {
			createVideo($('.video__source .js-videosrc')[0]);
		}
	});
})(jQuery);
