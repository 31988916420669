const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

(function($) {
	// var width;
	// var leftOffset;

	// $('.menu > li a').on('mouseenter', function() {
	// 	width = $(this).innerWidth();
	// 	leftOffset = $(this).position().left;

	// 	$('.bar').css('opacity', '1');
	// 	$('.bar').css('width', width);
	// 	$('.bar').css('transform', 'translate3d(' + leftOffset + 'px, 0, 0)');
	// });

	// $('.nav__menu li').on('mouseleave', function() {
	// 	$('.bar').css('opacity', '0');
	// 	$('.bar').css('width', '0px');
	// 	$('.bar').css('transform', 'translate3d(0, 0, 0)');
	// });

	let mobileBreakpoint = 767;

	$('.nav__menu .menu-item-has-children').on('mouseenter', function(e) {
		e.preventDefault();
		if ($(window).width() <= mobileBreakpoint) {
			return;
		}
        const $elm = $(this);
        $elm.addClass('is-active');
		var $subMenu = $(this)
			.find('.sub-menu-wrap');
		$subMenu.addClass('active');

        const offset = $elm.offset();
        const offsetParent = $elm.offsetParent();
        const parentWidth = offsetParent.width();
        const parentRight = offsetParent.offset().left + parentWidth;

        const submenuWidth = $subMenu.width();
        const submenuRight = offset.left + submenuWidth;

        const right = Math.max(0, parentRight - submenuRight);
        $subMenu.attr('style', `right: ${right}px`);

	});

	$('.nav__menu .menu-item-has-children').on('mouseleave', function(e) {
		e.preventDefault();
		if ($(window).width() <= mobileBreakpoint) {
			return;
		}
        const $elm = $(this);
        $elm.removeClass('is-active');
		var $subMenu = $(this)
			.find('.sub-menu-wrap');
		$subMenu.removeClass('active');
	});

	$('.nav__menu .menu-item-has-children > a').on('click', function(e) {
		e.preventDefault();
		if ($(window).width() <= mobileBreakpoint) {
			$(this).toggleClass('is-active');
			var $subMenu = $(this)
				.parent()
				.find('.sub-menu-wrap');
			$subMenu.toggleClass('active');
		}
	});

	let menuState = false;
	$('.hamburger').on('click', function() {
		menuState = !menuState;
		const targetElement = document.querySelector('#menu');
		if (menuState) {
			disableBodyScroll(targetElement);
			console.log('bodyscroll aan');
		} else {
			enableBodyScroll(targetElement);
			console.log('bodyscroll uit');
		}
		$('body').toggleClass('menu-active');
		$(this).toggleClass('is-active');
	});
})(jQuery);
