const mixitup = require('mixitup');
const mixitupMultifilter = require('../vendor/mixitup-multifilter.min.js');
const debounce = require('debounce');

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

const $ = jQuery || require('jquery');

export default class Mix {
	constructor($el) {
		const self = this;
		this.$container = $el;

		this.mixState = 'all';
		this.originalCollection = Array.from($('.mix'));
		this.collection = this.originalCollection;
		this.initialState = true;

		this.$filter = $('.filtercolumn');
		this.$form = $('#filters');

		$(document).on('click', '.btn-reset-filters', function (e) {
			e.preventDefault();
			self.reset();
		});

		$(document).on('click', '[data-filtercat]', function (e) {
			let target = $(this).data('filtercat');
			self.openMobileFilterSubmenu(target);
		});

		$(document).on('click', '.js-filter', function (e) {
			self.openMobileFilter();
		});

		$(document).on('click', '.filter__back .icon-icon-arrow', function (e) {
			self.closeMobileFilterSubmenu();
		});

		$(document).on('click', '.filter__back .icon-close', function (e) {
			self.closeMobileFilter();
		});
		$(document).on('click', '.filter__footer .btn', function (e) {
			self.closeMobileFilter();
		});


		const sortSelect = document.querySelector('#sort');
		const cstSel = sortSelect.customSelect;
		cstSel.select.addEventListener('change', e => {
			const value = cstSel.value;
			$('[name="sort[]"]').each((index, elm) => {
				$(elm).attr('checked', $(elm).attr('id') == value);
			}).trigger('change');
		});

		this.$form.on('submit', function (evt) {
			evt.preventDefault();
			const targetValue = $('[name="sort[]"]:checked').val();
			console.log('sort[] value', targetValue);
			//only call setter when targetValue differs
			if (cstSel.value != targetValue) {
				cstSel.value = targetValue;
			}
		});

		$('.form__radiobutton--filter input').on('change', function (e) {
			let text = $(this)
				.next('label')
				.text();

			$(document)
				.find('.js-sort-cat')
				.text(text);
		});

		this.$form.on('change', function () {
			$('body').addClass('is-filtered');
			self.$form.submit();
		});

		this.$form.on('submit', function (evt) {
			evt.preventDefault();
			debounce(() => {
				self.filter();
			}, 100)();
		});
	}

	init() {
		const self = this;
		mixitup.use(mixitupMultifilter);

		this.mixer = mixitup('#multimixcontainer', {
			animation: {
				duration: 400,
				queueLimit: 5,
			},
			multifilter: {
				enable: true,
			},
			load: {
				filter: 'none',
			},
		});

		self.filter(this.$form);
	}

	openMobileFilterSubmenu(target) {
		this.$filter.addClass('filters--sub-active');
		$(document)
			.find('[data-filter="' + target + '"]')
			.addClass('active');
	}

	closeMobileFilterSubmenu() {
		this.$filter.removeClass('filters--sub-active');
		$(document)
			.find('[data-filter]')
			.removeClass('active');
	}

	openMobileFilter() {
		this.$filter.addClass('filters--active');
		const targetElement = document.querySelector('.filtercolumn');

		disableBodyScroll(targetElement);
	}

	closeMobileFilter() {
		this.$filter.removeClass('filters--active');
		$(document)
			.find('[data-filter]')
			.removeClass('active');
		this.$filter.removeClass('filters--sub-active');
		const targetElement = document.querySelector('.filtercolumn');

		enableBodyScroll(targetElement);
	}

	filter() {
		let $form = this.$form;
		let self = this;
		let filterData = {};
		self.itemCount = 0;

		$($form.serializeArray()).each(function (index, obj) {
			var name = obj.name;
			if (/.*\[\]$/.test(name)) {
				name = name.substring(0, name.length - 2);
				if (typeof filterData[name] == 'undefined') {
					filterData[name] = [];
				}
				filterData[name].push(obj.value);
			} else {
				filterData[name] = obj.value;
			}
		});

		self.collection = self.originalCollection.filter(function (target) {
			let include = true;

			const $target = $(target);

			include = include && !$target.is('.fail-message');

			// Categories
			let itemCategories = $target.attr('data-categorieen') || [];
			if (filterData.category) {
				var subinclude = false;
				$.each(filterData.category, function (index, category) {
					subinclude =
						subinclude || itemCategories.indexOf(category) != -1;
				});
				include = include && subinclude;
			}

			// Trainingtype
			let itemTrainingtype = $target.attr('data-trainingtype');
			if (filterData.trainingtype) {
				include =
					include &&
					filterData.trainingtype.indexOf(itemTrainingtype) != -1;
			}

			// Duration
			let itemDuration = $target.attr('data-duration');
			if (filterData.duration) {
				include =
					include && filterData.duration.indexOf(itemDuration) != -1;
			}

			// Price
			let itemPrice = $target.attr('data-price');
			if (filterData.price) {
				include = include && filterData.price.indexOf(itemPrice) != -1;
			}

			if (include == true) {
				self.itemCount++;
			}

			// Initially include fail message
			if ($target.hasClass('fail-message')) {
				include = true;
			}

			return include;
		});

		// self.itemCount = self.collection.length;

		self.filteredCollection = self.collection.filter(function (target) {
			let include = true;

			if ($(target).hasClass('fail-message')) {
				if (self.itemCount == 0) {
					include = true;
				} else {
					include = false;
				}
			}

			return include;
		});

		const sortValue = $('[name="sort[]"]:checked').val() || $('[name="sort[]"]').val();

		self.filteredCollection = self.filteredCollection.sort((a,b) => {
			const [dataName, dir] = sortValue.split(/:/, 2);
			const attrName = 'data-' + dataName;

			const valA = a.getAttribute(attrName);
			const valB = b.getAttribute(attrName);

			const result = valA.localeCompare(valB);
			if(dir == 'desc'){
				return 0 - result;
			}
			return result;
		});

		self.initialState = self.collection.length == self.originalCollection.length;

		// In de initiele staat, moet er een USP block en trainingen eronder verschijnen. Hieronder is de offset daarvoor.
		if (self.initialState)
		{
			const $overflow = $('[data-role="training-overflow"]');
			const overflow = self.filteredCollection.slice(9);
			self.filteredCollection = self.filteredCollection.slice(0, 9);

			$overflow.children().remove();
			if(self.initialState){
				$(overflow)
					.clone(false)
					.attr('style', '')
					.appendTo($overflow);
			}
		}

		$('body').toggleClass('is-filtered', !self.initialState);

		$('.trainingcount').html('(' + self.itemCount + ')');
		$('.trainingcounter').html(' ' + self.itemCount + ' ');
		self.mixer.filter(self.filteredCollection);
		// self.mixer.sort(sortValue);
	}

	reset() {
		let self = this;

		self.initialState = true;

		this.$form.find('input').prop('checked', false);
		this.$form
			.find('select')
			.prop('selectedIndex', 0)
			.trigger('change');

	}
}
