const $ = jQuery || require('jquery');

export default class FilterState {
	constructor($el) {
		this.$el = $el;
		this.$form = $el.find('form');

		this.restoreHashState();
		this.listenToChanges();

		window.onhashchange = () => {
			this.restoreHashState();
		};
	}

	restoreHashState() {
		const hash = window.location.hash;
		if (hash && hash.length > 1) {
			let stateString = hash.substr(1);

			$('body').addClass('is-filtered');

			if (stateString == 'restore') {
				stateString = window.localStorage.getItem('filterstate') || '';
				const newLocation =
					location.protocol +
					'//' +
					location.host +
					location.pathname +
					'#' +
					stateString;
				window.history.replaceState({}, '', newLocation);
			}

			const filterState = this.parseQueryString(stateString);
			this.restoreState(filterState);
		}
	}

	restoreState(filterState) {
		this.$el.find('[data-filterstate]').each(function(index, elm) {
			const $elm = $(elm);

			let name = $elm.attr('name');
			if (name.endsWith('[]')) {
				name = name.substr(0, -2);
			}

			const value = filterState[name];
			const values = Array.isArray(value) ? value : [value];

			switch ($elm.prop('tagName')) {
				case 'SELECT':
					$elm.children('option').each((index, elm) => {
						const $option = $(elm);
						$option.prop(
							'selected',
							values.indexOf($option.val()) !== -1,
						);
					});
					break;

				case 'INPUT':
					switch ($elm.attr('type')) {
						case 'radio':
						case 'checkbox':
							$elm.prop(
								'checked',
								values.indexOf($elm.val()) !== -1,
							);
							break;

						default:
							$elm.val(value).trigger('proxy');
					}
					break;
			}
		});

		const $form = this.$form;
		$form.trigger('submit');
	}

	parseQueryString(queryString) {
		const parts = queryString.split('&');
		const values = {};
		let shortCircuit = false;

		parts.forEach(part => {
			let [name, value] = part.split('=', 2).map(decodeURIComponent);
			let $elm = null;

			if (name.endsWith('[]')) {
				$elm = $(`[name="${name}"][value="${value}"]`);
				name = name.substr(0, -2);
				if (typeof values[name] == typeof undefined) {
					values[name] = [];
				}
				values[name].push(value);
			} else {
				$elm = $(`[name="${name}"]`);
				values[name] = value;
			}

			if ($elm.prop('disabled')) {
				shortCircuit = true;
			}
		});

		if (shortCircuit) {
			history.replaceState('', document.title, window.location.pathname);
			return {};
		}

		return values;
	}

	listenToChanges() {
		this.$form.on('submit', (evt, ui) => {
			const filterState = [];
			this.$el.find('[data-filterstate]').each((index, elm) => {
				const $elm = $(elm);
				const name = $elm.attr('name');
				let value = false;

				switch ($elm.prop('tagName')) {
					case 'SELECT':
						value = $elm.children('option:selected').val();
						break;

					case 'INPUT':
						switch ($elm.attr('type')) {
							case 'radio':
							case 'checkbox':
								if ($elm.prop('checked')) {
									value = $elm.val();
								}
								break;

							default:
								value = $elm.val();
						}
						break;
				}
				if (value) {
					filterState.push(name + '=' + encodeURIComponent(value));
				}
			});

			const hash = filterState.join('&');

			localStorage.setItem('filterstate', hash);
			const newLocation =
				location.protocol +
				'//' +
				location.host +
				location.pathname +
				'#' +
				hash;

			if (window.location != newLocation) {
				window.history.pushState({}, '', newLocation);
			}
		});
	}
}
