var Parsley = require('parsleyjs');

(function($) {
	$(document).on('click', '.ajax-form button', function(e) {
		e.preventDefault();
		let $form = $(this).closest('form');
		let parsley = $form.parsley();
		var validate = parsley.validate();
		var isValid = parsley.isValid();
		if (!isValid) {
			return;
		}
		let url = $form.attr('action');
		let formData = $form.serialize();
		let $that = $(this);
		$(this).attr('disabled', true);

		$form.find('.error').remove();
		$.ajax({
			url: url,
			data: formData,
		}).done(function(response) {
			console.log(response);
			let html = response.data;
			if (response.success) {
				$form.find('.modal__content').html(html);
				$form.addClass('form-success');
			} else {
				$form
					.find('.modal-form')
					.append('<div class="error">' + html + '</div>');
				$form.addClass('form-error');
				$that.attr('disabled', false);
			}
		});
	});
})(jQuery);
